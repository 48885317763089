<template>
  <nav>
    <ul class="pagination pagination-md">
      <li class="page-item" v-if="meta_data.current_page != 1">
        <a href="#" class="page-link" @click="next(meta_data.current_page - 1)">
          &laquo;
        </a>
      </li>
      <li class="page-item disabled" v-else-if="meta_data.current_page === 1">
        <a href="#" class="page-link" @click="next(meta_data.current_page - 1)">
          &laquo;
        </a>
      </li>
      <li
        class="page-item"
        aria-hidden="true"
        v-if="meta_data.current_page > 4"
      >
        <a href="#" class="page-link" @click="next(1)">
          1
        </a>
      </li>
      <li class="page-item disabled" v-if="meta_data.current_page > 5">
        <a href="#" class="page-link">
          ...
        </a>
      </li>
      <li
        class="page-item"
        v-for="page in meta_data.last_page"
        :key="page"
        :class="{ active: meta_data.current_page === page }"
      >
        <a
          href="#"
          @click.prevent="next(page)"
          class="page-link"
          v-if="
            page >= meta_data.current_page - 3 &&
              page <= meta_data.current_page + 3
          "
        >
          {{ page }}
        </a>
      </li>
      <li
        class="page-item disabled"
        v-if="meta_data.current_page < meta_data.last_page - 4"
      >
        <a href="#" class="page-link">
          ...
        </a>
      </li>
      <li
        class="page-item"
        aria-hidden="true"
        v-if="meta_data.current_page < meta_data.last_page - 3"
      >
        <a href="#" class="page-link" @click="next(meta_data.last_page)">
          {{ meta_data.last_page }}
        </a>
      </li>
      <li
        class="page-item"
        :class="{ disabled: meta_data.current_page === meta_data.last_page }"
      >
        <a href="#" class="page-link" @click="next(meta_data.current_page + 1)">
          &raquo;
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: ['meta_data'],
  methods: {
    next(page) {
      this.$emit('next', page)
    },
  },
}
</script>
